.item1{
  color: #74e4c0;
  border-bottom: 2px solid #74e4c0;
  font-weight: 700;
}
.item2 {
  color: #74e4c0;
  border-bottom: 2px solid #74e4c0;
  font-weight: 700;
}
.midbar{
  background-image: url('./Codewiks/Homepage\ Image.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment:scroll;
  background-position: center;
 
}
.midbar1 {
  background-image: url('./Codewiks/Group\ 374.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-color: black;
}
.hero{
  background-image: url('./Codewiks/Group\ 170.png');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  background-attachment:scroll;
  background-color:rgb(22, 22, 22);
  z-index: -10;
}
.list{
  list-style-type:disc;
  accent-color: #74e4c0;
}
li{
  padding-top: 5px;
  margin-right: 20px;
}
.img5{
  background: rgba(180, 192, 224, 0.1);
}
.img4{
  background: rgba(89, 78, 218, 0.1);
}
.img3{
  background: rgba(36, 100, 223, 0.1);
}
.img2{
  background: rgba(37, 151, 215, 0.1);
}
.img1{
  background: rgba(116, 215, 228, 0.1);
}
.hero1 {
  background-image: url('./Codewiks/Group\ 62.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-attachment: scroll;
  background-color:rgb(13, 12, 12);
  z-index: -1;
}
.hero2{
  background-image: url('./Codewiks/Group\ 37.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-attachment: scroll;
    background-color:rgb(21, 21, 21);
}
.client{
  scale: 2;
}
.client1{
  scale:2.5;
}
.client2 {
  scale: 2;
}

.container{
  background-color:transparent;
    background-image: url('./Codewiks/quality.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right;
      background-attachment: scroll;
}
.container1 {
  background-color:transparent;
  background-image: url('./Codewiks/technology.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  background-attachment: scroll;
}
.container2 {
  background-color:#243038;
  background-image: url('./Codewiks/service.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}
.container3 {
  background-color: #171818;
  background-image: url('./Codewiks/contact.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}
.container4 {
  background-color: transparent;
  background-image: url('./Codewiks/contact.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
