@tailwind base;
@tailwind components;
@tailwind utilities;
html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  width:100%;
  overflow-x: hidden;
  box-sizing: border-box;

  font-family: 'Lato', sans-serif;
    font-family: 'League Spartan', sans-serif;
;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
