body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}

.container {
    padding: 20px;
}

.bg-black {
    background-color: #000 !important; /* Use !important to ensure the style takes precedence */
}

.text-white {
    color: #fff !important;
}
